<template>
  <div class="w-100 p-3 rounded mb-3" v-background-3>
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
      <h5 class="">Pack accueil</h5>
      <div>
        <BaseButton
          :loading="loadingDownload"
          @click="download(1)"
          icon="download"
          text="Documents RH"
        ></BaseButton>

        <BaseButton
          :loading="loadingDownload"
          @click="download(2)"
          icon="download"
          text="Documents mutuelle"
        ></BaseButton>

        <BaseButton
          :loading="loadingDownload"
          @click="download(3)"
          icon="download"
          text="Documents Q3SER"
        ></BaseButton>
      </div>
    </div>

    <div>
      <div class="mt-2 d-flex flex-column flex-md-row align-items-center">
        <div class="mr-3">
          <button-circle
            :loading="loading"
            v-if="!accueilChecked"
            name="circle"
            @click="checkAccueil"
            size="28"
          ></button-circle>
          <button-circle :loading="loading" v-if="accueilChecked" name="check-circle" size="28"></button-circle>
        </div>
        <div class="pr-3 text-justify">
          <small>
            En cochant cette case vous affirmez avoir lu et compris l'ensemble des documents transmis pour votre accueil
            chez Arkadia. Si cette case est déjà cochée, cela signifie que vous avez déjà validé la reception de ces
            documents au service RH (probablement via l'AR des documents d'accueil).</small
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ButtonCircle from "@/components/bases/ButtonCircle.vue";
import BaseButton from "@/components/bases/Button.vue";
export default {
  components: { ButtonCircle, BaseButton },
  data() {
    return {
      loading: false,
      loadingDownload: false,
      feedback: {},
      accueilChecked: false,
    };
  },
  computed: {
    documents() {
      let docs = [];
      if (!this.data) return [];
      this.data.forEach((d) => {
        if (d.documents && d.documents.length > 0)
          d.documents.forEach((e) => {
            docs.push(e.documents_qsser);
          });
      });
      console.log(docs);
      return docs;
    },
  },
  methods: {
    download(num_categorie) {

      let libelle = "";
      switch (num_categorie) {
        case 1:
          libelle = "RH";
          break;
        case 2:
          libelle = "mutuelle";
          break;
        case 3:
          libelle = "Q3SER";
          break;
      }

      this.loadingDownload = true;
      this.$http
        .get("/my-pack-accueil-download?num="+num_categorie, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "pack_accueil"+libelle+".zip");
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => (this.loadingDownload = false));
    },
    checkAccueil() {
      this.loading = true;
      this.$http
        .get("/my-pack-accueil-realise")
        .then(() => (this.accueilChecked = true))
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.loading = true;
    this.$http
      .get("/my-pack-accueil")
      .then((resp) => (this.accueilChecked = resp.data))
      .finally(() => (this.loading = false));
  },
};
</script>
<style>
  button {
    margin-right: 10px;
  }

</style>
